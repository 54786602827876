import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { Link } from "gatsby";
import Columns from "../components/columns";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import TryButton from "../components/TryButton";
import Container from "../components/container";
import styles from "./index.module.css";
import Lightbox from "../components/lightBox";

export const query = graphql`
  query {
    crmitel: file(relativePath: { eq: "new-tel.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "aktywnosc-cien.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "utraty-cien.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(relativePath: { eq: "konwersja-cien2.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "lejek-cien2.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(relativePath: { eq: "szanse-cien2.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(relativePath: { eq: "pulpit-nowy2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const SystemCRM = ({ data }) => {
  return (
    <FunctionPageLayout title="System CRM" headline="Co to jest i do czego służy?">
      <SEO
        title="Co to jest system CRM i do czego służy?"
        description="System CRM ✅ Wybierz proste i mobilne rozwiązanie • CRM dla firm • QuickCRM - 30 dni za darmo!"
      />
    <h1 style={{paddingBottom: 40}}>System CRM – jeden skrót, dwa aspekty</h1>

    <Container className="container container--text">
    <div className="text-answers">
    <div className="text-block">

    <p>Aby zrozumieć, czym jest system CRM warto zacząć of wyjaśnienia znaczenia 
    trzyliterowego skrótu, który jest częścią sformułowania. <strong>CRM, czyli 
    customer relationship management</strong> (z j. ang. zarządzanie relacjami 
    z&nbsp;klientami) to koncepcja, która zakłada, że jednym z&nbsp;najważniejszych celów 
    przedsiębiorstwa jest wysoka jakość obsługi klienta umożliwiająca 
    zaspokojenie jego potrzeb.</p>
    <p>Na tej podstawie wyrasta strategia, czy też <strong>filozofia 
    CRM</strong>, która skupia się na takim organizowaniu relacji z danym klientem firmy, 
    aby osiągać możliwie najwyższą satysfakcję ze współpracy. 
    </p>
    <p><strong>Poniżej znajdziesz przydatne informacje o działaniu systemów CRM, 
    a&nbsp;także odpowiedzi na pytania:</strong>
    </p>
    <p>
    <ul>
    <li> Co to jest system CRM?</li>
    <li> Do czego służy system informatyczny CRM?</li>
    <li> Jaka jest historia oprogramowania CRM?</li>
    <li> Jakie procesy obsługuje system zarządzania relacjami?</li>
    <li> Czym jest CRM online?</li>
    <li> Jaki system CRM wybrać dla małej firmy?</li>
    </ul>
    </p>
        </div>
    <div className="text-block">
          <Img
            style={{
              maxWidth: 400,
              margin: "0 auto"
            }}
            title="System CRM - mobilny"
            alt="system CRM - mobilny"
            fluid={data.crmitel.childImageSharp.fluid}
          />
        </div>
        </div>
        </Container>
      <hr />
      <Container className="container container--text">
      <h2 style={{textAlign: " center",
                  paddingBottom: 30}}>System CRM – co to jest?</h2>

      <p>Do realizacji filozofii CRM wykorzystuje się najczęściej <strong>system 
      informatyczny wspierający zarządzanie firmą handlową, czyli system 
      CRM</strong>. Jego zadaniem jest wsparcie procesu zarządzania relacjami z&nbsp;nowymi 
      klientami oraz obecnymi kontrahentami. Dzięki narzędziu CRM łatwiejsze staje się 
      planowanie i&nbsp;koordynowanie pracy działu handlowego oraz rejestrowanie wszystkich 
      kontaktów danej firmy z otoczeniem.
      </p>
      <p><strong>Wykorzystanie odpowiedniego systemu CRM umożliwia uporządkowanie 
      rozproszonej pracy umysłowej człowieka i w ten sposób pozwala pozyskiwać wiedzę 
      o jej efektywności oraz o&nbsp;możliwych sposobach na jej usprawnienie.</strong>
      </p>
      </Container>
      <Container className="container container--text">
      <h3 style={{textAlign: " center",
                  paddingBottom: 10}}>Program CRM kiedyś i&nbsp;teraz – historia CRM</h3>

      <p>Początki systemów CRM miały miejsce w&nbsp;latach 90. XX wieku w USA. Systemy te 
      były ówcześnie wyłącznie stacjonarne i dość toporne dla użytkowników. Bardzo 
      szybko jednak rozwinęły się, a wraz z rozwojem sieci oraz urządzeń mobilnych 
      zwiększyły się możliwości systemu CRM i pojawił się <strong>model 
      SaaS</strong> (oprogramowanie jako usługa). Pozwala on na <strong>obsługę 
      systemu CRM z&nbsp;poziomu przeglądarki bez konieczności instalowania go na 
      komputerze</strong>. To tzw. System CRM w Saas-ie lub CRM online. 
      </p>
      </Container>
      <Container className="container container--text">
      <h3 style={{textAlign: " center",
                  paddingBottom: 10}}>Chmurowy system CRM</h3>

      <p>
      System CRM online to dobre rozwiązanie dla nowoczesnego przedsiębiorstwa chcącego
      prowadzić efektywną obsługę klientów. Jego główną zaletą jest to, że istnieje <strong>możliwość
      korzystania z niego na każdym urządzeniu z dostępem do Internetu</strong>. Ułatwia 
      to dzielenie się informacjami wśród pracowników (użytkowników systemu CRM) i&nbsp;wspiera 
      bezpieczeństwo danych. Dzięki systemowi CRM w takiej formie nie zdarzy się sytuacja, 
      w której utracisz kluczowe informacje na skutek awarii dysku, bo będą przechowywane one
      w modelu cloud (chmura plików).
      </p>
      <p>
      Chmurowy system CRM pozwala bardzo szybko rozpocząć pracę z nim bez długiej 
      konfiguracji sprzętowej czy instalowania na wielu urządzeniach należących do
      pracowników działu obsługi klienta. Najczęściej wystarczy kilka chwil, 
      żeby wypróbować taki rodzaj systemu CRM.
      </p>
      <Lightbox
                style={{
                  maxWidth: 1000,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.zdjecie7]}
                alts={["nowoczesny system CRM"]}
              />
     </Container>
     <Container className="container container--text">
    <h2 style={{textAlign: " center",
                  paddingBottom: 30}}>Kto powinien wdrożyć system CRM?</h2>

    <p>System klasy CRM sprawdzi się zarówno w&nbsp;małym przedsiębiorstwie, jak i dużej firmie. 
    Jego zadaniem jest <strong>wsparcie procesów biznesowych nakierowanych na sprzedaż 
    i budowanie relacji z klientami</strong>. Te mogą zachodzić w firmach różnych 
    rozmiarów, dlatego oprogramowanie CRM pomaga sprawnie funkcjonować różnym 
    organizacjom i podnosi efektywność prowadzonych działań niezależnie od rozmiaru.
    </p>
    <p>Jednym z większych problemów każdego z&nbsp;tych podmiotów jest chaos spowodowany 
    brakiem sposobu na porządkowanie danych o potencjalnych klientach, a&nbsp;także stałych
    kontrahentach. Przez to proces obsługi klienta nie jest prowadzony w sposób optymalny 
    i wiele tematów nie zostaje zrealizowanych w&nbsp;odpowiednim terminie, a&nbsp;nawet 
    całkowicie umyka.
    </p>
    <p><strong>Jeśli chcesz, aby Twój dział handlowy mógł działać sprawniej i szybciej, 
    zdecydować się na wdrożenie systemu CRM do zarządzania klientami i&nbsp;tematami sprzedażowymi.</strong>
    </p>
    </Container>
    <Container className="container container--text">
      <h3 style={{textAlign: " center",
                  paddingBottom: 10}}>Korzyści z&nbsp;wprowadzenia systemu CRM w&nbsp;firmie</h3>
    <p>Dobry system CRM wykorzystywany w&nbsp;przedsiębiorstwie sprawi, że Twoja firma 
    będzie skuteczniej obsługiwać swoich klientów, a oni będą oni bardziej 
    usatysfakcjonowani ze współpracy. Jak? System CRM daje Ci narzędzie do obsługi danych
    dotyczących klientów, dzięki któremu Twoi handlowcy będą dużo lepiej przygotowani 
    do reagowania na potrzeby kontrahentów. Dzięki systemowi zarządzania relacjami 
    wzrasta szansa, że większe grupy klientów będą chętniej decydować się na kontynuację 
    zawartej współpracy.
    </p>
    </Container>
    <Container className="container container--text">
      <h3 style={{textAlign: " center",
                  paddingBottom: 10}}>Wdrożenie systemu - w&nbsp;czym może pomóc?</h3>
    <p>Wdrożenie oprogramowanie CRM może rozwiązać problemy, które napotyka wiele przedsiębiorstw:
    </p>
    <h4 style={{paddingBottom:20,
      paddingTop:20}}><strong>Nieobecności pracowników - brak obsługi klienta?</strong></h4>
    <p>
    Wyobraź sobie, że pracownik, który miał przeprowadzić dzisiaj spotkanie z&nbsp;potencjalnym 
    klientem, zachorował i nie pojawił się w pracy. Musisz szybko znaleźć zastępstwo, 
    bo klient już do Ciebie jedzie. Co robisz? Bez odpowiedniego narzędzia CRM jesteś 
    bezradny – wszystkie informacje o Waszej dotychczasowej współpracy, potrzebach 
    klienta i jego możliwościach finansowych znajdują się u nieobecnego handlowca. 
    Wdrożenie w firmie systemu CRM ma sprawić, że <strong>wszystkie informacje, 
    niezbędne do prowadzenia spotkania będę bezpieczne i dostępne dla każdej 
    uprawnionej osoby.</strong>
    </p>

    <h4 style={{paddingBottom:20,
      paddingTop:20}}><strong>Monitorowanie procesu sprzedaży</strong></h4>
    <p>
    Wielu menedżerów sprzedaży ma problem z&nbsp;określeniem, jakie aktywności zostały 
    wykonane względem danego kontrahenta i&nbsp;danej szansy sprzedażowej. Brak narzędzia, 
    które porządkuje pracę umysłową handlowców sprawia, że jest ona trudna w&nbsp;ewaluacji.
    Każdy dobry system CRM jest wyposażony w <strong>kalendarz, bazę kontrahentów 
    i funkcję szans sprzedaży</strong>, które porządkują bazę danych klientów, 
    działaniach handlowców i informacje o&nbsp;postępach w procesie sprzedaży. Pozwala 
    to monitorować pracę i podejmować najlepsze decyzje sprzedażowe.
    </p>
    <h4 style={{paddingBottom:20,
      paddingTop:20}}><strong>Uciążliwe budowanie bazy danych</strong></h4>
    <p>
    Kolejnym z problemów, których doświadczają handlowcy, jest uciążliwe 
    i&nbsp;czasochłonne zbieranie danych niezbędnych do sporządzania raportów. 
    Poszczególni pracownicy mają swoje własne sposoby na notowanie informacji, więc 
    ujednolicenie tych danych może przyprawić o ból głowy. <strong>Chmurowy system 
    CRM gromadzi wszelkie kluczowe dane i&nbsp;porządkuje je w spójny sposób.</strong>
    </p>
    <p>
    Zdecyduj się na wdrożenie CRM i&nbsp;rozwiąż swoje problemy!
    </p>

          </Container>
    <Container className="container container--text">
    <h2 style={{textAlign: " center",
                  paddingBottom: 30}}>Raportowanie dzięki systemowi CRM</h2>
    <p>
    Jedną z zalet systemu CRM jest fakt, że to rozwiązanie pozwala na <strong>automatyczne 
    zestawienie informacji o działaniach handlowych</strong>. Szereg raportów, 
    dotyczących procesu sprzedaży daje szansę na szybkie identyfikowanie zagrożeń 
    i reagowanie na zmieniającą się dynamicznie sytuację.
    </p>
    <p><strong>Oto najbardziej przydane raporty w&nbsp;oprogramowaniu CRM:</strong></p>
    <p><strong>Lejek szans sprzedaży</strong> ma za zadanie monitorowanie kampanii 
    sprzedażowych. Pozwala na oszacowanie liczby szans i&nbsp;zysków. Pomaga zweryfikować 
    prawdopodobieństwo osiągnięcia targetów.</p>
    <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                  paddingTop:20,
                  paddingBottom:20,
                }}
                images={[data.zdjecie5]}
                alts={["System CRM - lejek sprzedaży"]}
              />
    </Container>
    <Container className="container container--text">
    <p><strong>Konwersja lejka</strong> informuje o tym, jaki procent szans przechodzi 
    między etapami i&nbsp;daje wiedzę o tzw. wąskich gardłach, czyli o&nbsp;częściach procesu, 
    w których spada skuteczność Twoich handlowców.</p>
    <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                  paddingTop:20,
                  paddingBottom:20,
                }}
                images={[data.zdjecie4]}
                alts={["System CRM - konwersja lejka"]}
              />
    </Container>
    <Container className="container container--text">
    <p><strong>Przyczyny utraty</strong> informują o tym, dlaczego szanse zostały 
    przegrane. W momencie oznaczenia szansy jako utraconej w&nbsp;systemie CRM, handlowcy 
    są proszeni o&nbsp;podanie powodu. Raport ten ukazuje najczęściej podawane przez nich 
    przyczyny.
    </p>
    <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                  paddingTop:20,
                  paddingBottom:20,
                }}
                images={[data.zdjecie3]}
                alts={["System CRM - przyczyny utraty"]}
              />
        </Container>
        <Container className="container container--text">
        <p><strong>Aktywność handlowców</strong> umożliwia przeanalizowanie typów 
        podejmowanych przez pracowników działań. Dostarcza informacji, jakiego 
        rodzaju kroki podjęli handlowcy, aby osiągnąć cel sprzedażowy. Tą wiedzę 
        można wykorzystać, organizując ich pracę w przyszłości.
        </p>
        <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                  paddingTop:20,
                  paddingBottom:20,
                }}
                images={[data.zdjecie2]}
                alts={["System CRM - aktywność handlowców"]}
              />
        </Container>
        <Container className="container container--text">
        <h2 style={{textAlign: " center",
                  paddingBottom: 30}}>Funkcje systemu CRM</h2>
        <p>
        Oprócz wspierania procesów sprzedażowych, dobry system CRM poprawia 
        efektywność pracowników. Pozwala on skutecznie zarządzać, planować 
        i&nbsp;koordynować ich pracą. Organizacja pracy umysłowej dzięki narzędziom 
        CRM to szybsze i łatwiejsze realizowanie celów i&nbsp;sprawne identyfikowanie zagrożeń.</p>
        </Container>
        <Container className="container container--text">
        <h4 style={{textAlign: " left",
                  paddingBottom: 10}}>Wśród najważniejszych funkcji systemu CRM znajdują się:</h4>
        <p>
        <ul>
        <li> <Link to="/sprzedaz-w-crm/">Zarządzanie sprzedażą</Link> – szanse sprzedaży w CRM pozwalają na obsługę wielu procesów sprzedażowych.</li>
        <li> <Link to="/raporty/">Raporty</Link> – system CRM zbiera samodzielnie dane i generuje raporty automatycznie.</li>
        <li> <Link to="/kartoteki-kontrahentow/">Kartoteki kontrahentów</Link> – w systemach CRM przechowuje się uporządkowany zbiór danych CRM pozwalający na stały dostęp do wiedzy o klientach danej firmy i&nbsp;zarządzanie kontaktami z nimi.</li>
        <li> <Link to="/kalendarz-i-zadania/">Planowanie zadań</Link> – interaktywne narzędzie, które służy do planowania i&nbsp;koordynacji pracy działu handlowego.</li>
        </ul>
        </p>
        </Container>
        <Container className="container container--text">
        <h2 style={{textAlign: " center",
                  paddingBottom: 30}}>Jaki system CRM wybrać dla małej firmy?</h2>
        <p>Proces wybierania systemu CRM dla małej firmy stanowi często duże wyzwanie. 
        Ogrom dostępnych na rynku rozwiązań, które celują w małe przedsiębiorstwa 
        nie sprzyja podejmowaniu szybkiej decyzji. Ważne jest jednak to, żeby dany 
        system CRM zezwalał na obsługę procesów, które występują właśnie w Twojej 
        firmie. Dedykowane systemy CRM nie są potrzebne na samym początku. 
        Wystarczające są proste wersje systemu CRM stanowiące podstawową bazę danych CRM.
        </p>
        <p>Przeczytaj więcej o funkcjonalnościach systemu <Link to="/crm-dla-malej-firmy/">CRM dla małej firmy.</Link>
        </p>

      </Container>
      <Container className="container container--text">
      <h3 style={{textAlign: " center",
                  paddingBottom: 10}}>Procesy wspierane przez funkcje systemu CRM</h3>
      <p>
      Rozwiązania CRM mogą ułatwić planowanie i realizowanie różnego rodzaju procesów, 
      a&nbsp;należą do nich:
      </p>
      <p><strong>Proces pozyskiwania kontraktów (tzw. sprzedaż projektowa)</strong>, 
      w którym przedmiotem sprzedaży nie jest gotowy produkt, a rozwiązanie, które 
      jest dostosowywane do wymagań klienta. Jest on długofalowy i czasochłonny 
      oraz wymaga od handlowca szczególnej wiedzy eksperckiej.
      </p>
      <p><strong>Budowa kanału sprzedaży pośredniej</strong>, która polega na przekazywaniu 
      produktów pomiędzy producentem, a odbiorcą z&nbsp;wykorzystaniem podmiotów trzecich. 
      Proces ten zakłada dotarcie do pośrednika, a nie do klienta końcowego.
      </p>
      <p> <strong>Budowa sieci stałych odbiorców końcowych</strong>, mającej za zadanie 
      pozyskanie i utrzymywanie współpracy z kontrahentem końcowym, który będzie pobierał 
      produkt bezpośrednio od producenta.
      </p>
      <p><strong>Proces rozwoju relacji z klientem</strong>, czyli tzw. ponowienia, 
      cross–selling i up–selling. Cross-selling to technika, w której klientowi 
      proponuje się dobra komplementarne, czyli takie które uzupełnią już zakupione. 
      Natomiast up–selling to technika sprzedaży, w ramach której proponuje się 
      klientowi produkty lub usługi o wyższym standardzie czy wersji.
      </p>
      <p><strong>Wprowadzanie produktów</strong>, czyli obsługa wejścia na rynek nowej 
      usługi czy produktu.
      </p>
      <p><strong>Proces utrzymania współpracy z&nbsp;klientem</strong>, polegający na 
      utrzymywaniu pozytywnych relacji z kontrahentem i&nbsp;sprawne zarządzania obsługą klientów.
      </p>

      <p>
      Oto przykładowa kampania sprzedaży projektowej w systemie CRM. Zobacz jak systemy 
      CRM zapewniają efektywne zarządzanie potencjalnymi klientami:
      </p>

      <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                  paddingTop:20,
                  paddingBottom:20,
                }}
                images={[data.zdjecie6]}
                alts={["System CRM - proces sprzedaży"]}
              />
        </Container>
        <Container className="container container--text">
        <h4><strong>Dowiedz się więcej o&nbsp;systemie CRM:</strong></h4>
        <p>
        <ul>
        <li> <Link to="/filozofia-crm/">Filozofia CRM</Link></li>
        <li> <Link to="/crm/">CRM - Wprowadzenie</Link></li>
        <li> <Link to="/pierwsze-kroki-w-crm/">Pierwsze kroki w&nbsp;systemie</Link></li>
        </ul>
        </p>
        </Container>


    </FunctionPageLayout>
  );
};

export default SystemCRM;
